import store from '../store';
import { useRef } from 'react';
// == Azure ==

// == Azure ==

export default function navigate(history, path, params = {}) {
  store.setHistory(history);
  history.push(path, params);
  if (!isLogin() && path == '/login') {
    window.location.href = path;
  }
}

/**
 * 函数在未运行完成前 锁死
 *
 * @export
 * @template T
 * @param {T} fun
 * @param {number} [delay=200]
 * @returns
 */
export function useLockFunction(fun, delay = 200) {
  // 存储去抖动后的值
  const runing = useRef(false);
  return async (...args) => {
    if (!runing.current) {
      runing.current = true;
      try {
        const data = await fun.apply(this, args);
        runing.current = false;
        return data;
      } catch (error) {
        runing.current = false;
        throw error;
      }
    }
  };
}
// 字符串超过某个值显示...
export function formateStr(str, num = 16) {
  if (!str) {
    return '';
  }
  return str.length > 16 ? str.slice(0, num) + '...' : str;
}

// 格式化时间
export function formatDate(time, type = 'hour') {
  if (!time) {
    return '';
  }
  const date = new Date(time);
  const year = date.getFullYear();
  const month = `${date.getMonth() + 1 < 10 ? '0' : ''}${date.getMonth() + 1}`;
  const day = `${date.getDate() < 10 ? '0' : ''}${date.getDate()}`;
  const hour = `${date.getHours() < 10 ? '0' : ''}${date.getHours()}`;
  const minutes = `${date.getMinutes() < 10 ? '0' : ''}${date.getMinutes()}`;
  const second = `${date.getSeconds() < 10 ? '0' : ''}${date.getSeconds()}`;
  if (type === 'month') {
    return `${year}/${month}/${day}`;
  }
  return `${year}/${month}/${day} ${hour}:${minutes}:${second}`;
}

export function numberToString(number) {
  return number ? number + '' : '';
}

export function isNull(text) {
  return text === undefined || text === null || text === '';
}

// 通过检查session是否存有userInfo判断是否login
export function isLogin() {
  return !!getUserInfo();
}

// (如果没有login)，访问的页面不是'', 'login', 'protocol'，需要redirect
export function isRedirect() {
  return (
    ['', 'login', 'protocol', 'policy_zh', 'policy_en'].indexOf(
      window.location.pathname.replaceAll('/', '')
    ) == -1
  );
}

// 获取session的userinfo
export function getUserInfo() {
  return sessionStorage.getItem('userInfo');
}
export function getUserCookies() {
  return localStorage.getItem('cookiesCheck');
}

// 解析 userinfo
export function getParsedUserInfo() {
  return JSON.parse(getUserInfo());
  // == Azure ==
  // let userInfo = JSON.parse(getUserInfo());
  // if(userInfo) {
  //     userInfo  = userInfo.response;
  // }
  // return userInfo;
  // == Azure ==
}

// == Azure ==
export function getParsedToken() {
  let userInfo = JSON.parse(getUserInfo());
  let token = '';
  if (userInfo) {
    token = userInfo.token;
  }
  return token;
}
// == Azure ==

export function checkCommaValid(str) {
  const arr = str.replaceAll('，', ',').split(',');
  return (
    arr.length > 1 &&
    arr.filter((s) => {
      return s.replace(/\s*/g, '').length == 0;
    }).length == 0
  );
}

export const limitDecimalsF = (value) => {
  let reg = /^(-)*(\d+)\.(\d\d).*$/;
  return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '').replace(reg, '$1$2.$3');//NOSONAR
};

export const limitDecimalsP = (value) => {
  let reg = /^(-)*(\d+)\.(\d\d).*$/;
  return value.replace(/\s?|(,*)/g, '').replace(reg, '$1$2.$3');
};

export const displayProductName = (name, locale) => {
  let nameArr = name.split('/');
  if (nameArr.length > 1) {
    if (locale === 'en') {
      return nameArr[0];
    } else {
      return nameArr[1];
    }
  } else {
    return name
  }
}