import React, {Suspense, useEffect, useState} from 'react';
import './App.scss';
import {BrowserRouter} from 'react-router-dom';
import {isLogin} from './utils';
import './i18n/i18n';
import {ErrorBoundary} from 'react-error-boundary'
import ErrorIcon from '../src/assets/images/common/error.png';
import {Button} from "antd";

const UnauthenticatedRouter = React.lazy(() =>
  import('./router/unauthenticatedRouter')
);
const AuthenticatedRouter = React.lazy(() =>
  import('./router/authenticatedRouter')
);

function ErrorFallback({error, resetErrorBoundary}) {
  return (
    <div style={{display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}} role="alert">
      <div style={{display: 'flex', width: 500, height: 200, marginTop: 50, justifyContent: 'center', alignItems: 'center'}}>
        <img src={ErrorIcon} alt="" />
      </div>
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <Button type="primary" onClick={resetErrorBoundary}>Try again</Button>
    </div>
  )
}

function App(props) {
  const [menuIndex, setMenuIndex] = useState(0);

  const login = isLogin();

  useEffect(() => {
    document.addEventListener('menuClick', () => {
      const index = localStorage.getItem('menuIndex');
      localStorage.removeItem('menuIndex');
      setMenuIndex(Number(index));
      setTimeout(() => {
        setMenuIndex(666);
      }, 1000);
    });
  }, []);

  return (
    <BrowserRouter>
      <Suspense fallback={<div/>}>
        {login ? (
          <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onReset={() => {
              // reset the state of your app so the error doesn't happen again
            }}
          >
            <AuthenticatedRouter menuIndex={menuIndex}/>
          </ErrorBoundary>
        ) : (
          <UnauthenticatedRouter/>
        )}

      </Suspense>
    </BrowserRouter>
  );
}

export default App;
