/**
 * Dear programmer:
 * When I wrote this code, only god and I knew how it worked.
 * Now, only god knows it!
 *
 * @author: Pegasus  Date: 2021/11/22 Time: 17:52
 */

import en from './en.json'
import zh from './zh.json'

const resources = {
  en: {
    translation: en
  },
  zh: {
    translation: zh
  }
}

export default resources;