

class Store {
    history = []
    userInfo = {}
    kilnId = '-'
    resMachinery = {}
    resRefractories = {}
    resFuel = {}
    resChemical = {}
    disableFlag= true;
    pageKey = "1"
    hasChanged = false

    initTechData(){
        this.resMachinery = {}
        this.resRefractories = {}
        this.resFuel = {}
        this.resChemical = {}
    }
    setPageKey(num){
        this.pageKey = num
    }
    setHistory(value) {
        this.history = value
    }
    setUserInfo(value) {
        this.userInfo = value
    }
    setHasChanged(bol) {
        this.hasChanged = bol
    }
    setDisableFlag(value){
        this.disableFlag = value
    }
    setKilnId(value) {
        this.kilnId = value
    }
    //machinery
    setResMachinery(obj){
        this.resMachinery = obj
    }
    picMachinery = []
    setPicMachinery(arr) {
      this.picMachinery = arr
    }

    fileMachinery = []
    setFileMachinery(arr) {
        this.fileMachinery = arr
    }
    //refractories
    setResRefractories(obj){
        this.resRefractories = obj
    }

    setRefractories(obj){
        this.refractories = obj
    }

    picRefractories = []
    setPicRefractories(arr) {
      this.picRefractories = arr
      //alert(arr)
    }
    

    fileRefractories = []
    setFileRefractories(arr) {
        this.fileRefractories = arr
    }

    //fuel
    setResFuel(obj){
        this.resFuel = obj
    }
    picFuel = []
    setPicFuel(arr) {
      this.picFuel = arr
    }

    fileFuel = []
    setFileFuel(arr) {
        this.fileFuel = arr
    }

    //chemical
    setResChemical(obj){
        this.resChemical = obj
    }

    picChemical = []
    setPicChemical(arr) {
      this.picChemical = arr
    }

    fileChemical = []
    setFileChemical(arr) {
        this.fileChemical = arr
    }

    

    //mechanical view info
    
    // ellipticityDate = '';
    // setEllipticityDate(value){
    //     this.ellipticityDate = value
    //     alert(value);
    // }
    // ellipticity = {};
    // setEllipticity(value){
    //     this.ellipticity = value
    // }
    // ellipticityUp1='';
    // setEllipticityUp1(value){
    //     this.ellipticityUp1 = value
    //     alert(this.ellipticityUp1)
    // }
    
    // ellipticityDown1='';
    // setEllipticityDown1(value){
    //     this.ellipticityDown1 = value
    // }
    // ellipticityKey1='';
    // setEllipticityKey1(value){
    //     this.ellipticityKey1 = value
    // }
    // ellipticityUp2='';
    // setEllipticityUp2(value){
    //     this.ellipticityUp2 = value
    // }
    // ellipticityDown2='';
    // setEllipticityDown2(value){
    //     this.ellipticityDown2 = value
    // }
    // ellipticityKey2='';
    // setEllipticityKey2(value){
    //     this.ellipticityKey2 = value
    // }
    // ellipticityUp3='';
    // setEllipticityUp3(value){
    //     this.ellipticityUp3 = value
    // }
    // ellipticityDown3='';
    // setEllipticityDown3(value){
    //     this.ellipticityDown3 = value
    // }
    // ellipticityKey3='';
    // setEllipticityKey3(value){
    //     this.ellipticityKey3 = value
    // }

    // tireClearanceDate = '';
    // setTireClearanceDate(value){
    //     this.tireClearanceDate = value
    // }
    // tireClearance = '';
    // setTireClearance(value){
    //     this.tireClearance = value
    // }
    // tireClearanceUp1='';
    // setTireClearanceUp1(value){
    //     this.tireClearanceUp1 = value
    // }
    // tireClearanceDown1='';
    // setTireClearanceDown1(value){
    //     this.tireClearanceDown1 = value
    // }
    // tireClearanceKey1='';
    // setTireClearanceKey1(value){
    //     this.tireClearanceKey1 = value
    // }
    // tireClearanceUp2='';
    // setTireClearanceUp2(value){
    //     this.tireClearanceUp2 = value
    // }
    // tireClearanceDown2='';
    // setTireClearanceDown2(value){
    //     this.tireClearanceDown2 = value
    // }
    // tireClearanceKey2='';
    // setTireClearanceKey2(value){
    //     this.tireClearanceKey2 = value
    // }
    // tireClearanceUp3='';
    // setTireClearanceUp3(value){
    //     this.tireClearanceUp3 = value
    // }
    // tireClearanceDown3='';
    // setTireClearanceDown3(value){
    //     this.tireClearanceDown3 = value
    // }
    // tireClearanceKey3='';
    // setTireClearanceKey3(value){
    //     this.tireClearanceKey3 = value
    // }

    // tireMigrationDate = '';
    // setTireMigrationDate(value){
    //     this.tireMigrationDate = value
    // }
    // tireMigration='';
    // setTireMigration(value){
    //     this.tireMigration = value
    // }
    // tireMigrationUp1='';
    // setTireMigrationUp1(value){
    //     this.tireMigrationUp1 = value
    // }
    // tireMigrationDown1='';
    // setTireMigrationDown1(value){
    //     this.tireMigrationDown1 = value
    // }
    // tireMigrationKey1='';
    // setTireMigrationKey1(value){
    //     this.tireMigrationKey1 = value
    // }

    // tireMigrationUp2='';
    // setTireMigrationUp2(value){
    //     this.tireMigrationUp2 = value
    // }
    // tireMigrationDown2='';
    // setTireMigrationDown2(value){
    //     this.tireMigrationDown2 = value
    // }
    // tireMigrationKey2='';
    // setTireMigrationKey2(value){
    //     this.tireMigrationKey2 = value
    // }

    // tireMigrationUp3='';
    // setTireMigrationUp3(value){
    //     this.tireMigrationUp3 = value
    // }
    // tireMigrationDown3='';
    // setTireMigrationDown3(value){
    //     this.tireMigrationDown3 = value
    // }
    // tireMigrationKey3='';
    // setTireMigrationKey3(value){
    //     this.tireMigrationKey3 = value
    // }
   
    // kilnShellOperation=[];
    // setKilnShellOperation(value){
    //     this.kilnShellOperation = value
    // }
    
    // picMechanicalListStore=[];
    // setPicMechanicalListStore(value){
    //     this.picMechanicalListStore =value;
        
    // }
    // pushPicMechanicalListStore(obj){
    //     let arr=this.picMechanicalListStore;
    //     arr.push(obj);
    //     this.picMechanicalListStore=arr;
    // }
    // deletePicMechanicalListStore(index){
    //     let arr=this.picMechanicalListStore.slice(index,1);
    //     this.picMechanicalListStore=arr;
        
    // }
    // fileMechanicalListStore=[];
    // setFileMechanicalListStore(value){
    //     this.fileMechanicalListStore =value;
        
    // }
    // pushFileMechanicalListStore(obj){
    //     let arr=this.fileMechanicalListStore;
    //     arr.push(obj);
    //     this.fileMechanicalListStore=arr;
    // }
    // deleteFileMechanicalListStore(index){
    //     let arr=this.fileMechanicalListStore.slice(index,1);
    //     this.fileMechanicalListStore=arr;
        
    // }
   
    // //refactory brick list
    // refractories = {};
    // setRefractories(value){
    //     this.refractories = value
    // }
    // refractoriesLayingAreaDate = ''
    // setRefractoriesLayingAreaDate(value){
    //     this.refractoriesLayingAreaDate = value;
    // }
    // refractoriesLayingAreaContent = ''
    // setRefractoriesLayingAreaContent(value){
    //     this.refractoriesLayingAreaContent = value;
    // }
    // refractoriesDate = '';
    // setRefractoriesDate(value){
    //     this.refractoriesDate = value
    // }
    // refractoriesLiningDate = '';
    // setRefractoriesLiningDate (value){
    //     this.refractoriesLiningDate = value
    // }
    // refractoriesThickness = '';
    // setRefractoriesThickness(value){
    //     this.refractoriesThickness = value
    // }
    // refractoriesProduction = '';
    // setRefractoriesProduction(value){
    //     this.refractoriesProduction = value
    // }
    // refractoriesPosition = '';
    // setRefractoriesPosition(value){
    //     this.refractoriesPosition = value
    // }
    // refractoriesIndex = '';
    // setRefractoriesIndex(value){
    //     this.refractoriesIndex = value
    // }
    // picRefactoriesListStore=[];
    // setPicRefactoriesListStore(value){
    //     this.picRefactoriesListStore =value;
        
    // }
    // pushPicRefactoriesListStore(obj){
    //     let arr=this.picRefactoriesListStore;
    //     arr.push(obj);
    //     this.picRefactoriesListStore=arr;
    // }
    // deletePicRefactoriesListStore(index){
    //     let arr=this.picRefactoriesListStore.slice(index,1);
    //     this.picRefactoriesListStore=arr;
        
    // }
    // fileRefactoriesListStore=[];
    // setFileRefactoriesListStore(value){
    //     this.fileRefactoriesListStore =value;
        
    // }
    // pushFileRefactoriesListStore(obj){
    //     let arr=this.fileRefactoriesListStore;
    //     arr.push(obj);
    //     this.fileRefactoriesListStore=arr;
    // }
    // deleteFileFuelListStore(index){
    //     let arr=this.fileFuelListStore.slice(index,1);
    //     this.fileFuelListStore=arr;
        
    // }

    // //chemical list
    // picChemicalListStore=[];
    // setPicChemicalListStore(value){
    //     this.picChemicalListStore =value;
        
    // }
    // pushPicChemicalListStore(obj){
    //     let arr=this.picChemicalListStore;
    //     arr.push(obj);
    //     this.picChemicalListStore=arr;
    // }
    // deletePicChemicalListStore(index){
    //     let arr=this.picChemicalListStore.slice(index,1);
    //     this.picChemicalListStore=arr;
        
    // }
    // fileChemicalListStore=[];
    // setFileChemicalListStore(value){
    //     this.fileChemicalListStore =value;
        
    // }
    // pushFileChemicalListStore(obj){
    //     let arr=this.fileChemicalListStore;
    //     arr.push(obj);
    //     this.fileChemicalListStore=arr;
    // }
    // deleteFileChemicalListStore(index){
    //     let arr=this.fileChemicalListStore.slice(index,1);
    //     this.fileChemicalListStore=arr;
        
    // }

    // //fuel list
    // fuelTemperature='';
    // setFuelTemperature(value){
    //     this.fuelTemperature = value
    // }
    // fuelTemperatureTmp='';
    // setFuelTemperatureTmp(value){
    //     this.fuelTemperatureTmp = value
    // }
    // fuelTemperatureDate='';
    // setFuelTemperatureDate(value){
    //     this.fuelTemperatureDate = value
    // }
    // fuelTemperatureLocation='';
    // setFuelTemperatureLocation(value){
    //     this.fuelTemperatureLocation = value
    // }
    // mainFuel=''
    // setMainFuel(value){
    //     this.mainFuel =value;
    // }
    // mainFuelContent=''
    // setMainFuelContent(value){
    //     this.mainFuelContent = value; 
    // }
    // mainFuelDate=''
    // setMainFuelDate(value){
    //     this.mainFuelDate = value; 
    // }
    // alternativeFuel='';
    // setAlternativeFuel(value){
    //     this.alternativeFuel = value;
    // }
    // alternativeFuelContent='';
    // setAlternativeFuelContent(value){
    //     this.alternativeFuelContent = value;
    // }
    // alternativeFuelDate='';
    // setAlternativeFuelDate(value){
    //     this.alternativeFuelDate = value;
    // }

    // picFuelListStore=[];
    // setPicFuelListStore(value){
    //     this.picFuelListStore =value;
        
    // }
    // pushPicFuelListStore(obj){
    //     let arr=this.picFuelListStore;
    //     arr.push(obj);
    //     this.picFuelListStore=arr;
    // }
    // deletePicFuelListStore(index){
    //     let arr=this.picFuelListStore.slice(index,1);
    //     this.picFuelListStore=arr;
        
    // }
    // fileFuelListStore=[];
    // setFileFuelListStore(value){
    //     this.fileFuelListStore =value;
        
    // }
    // pushFileFuelListStore(obj){
    //     let arr=this.fileFuelListStore;
    //     arr.push(obj);
    //     this.fileFuelListStore=arr;
    // }
    // deleteFileFuelListStore(index){
    //     let arr=this.fileFuelListStore.slice(index,1);
    //     this.fileFuelListStore=arr;
        
    // }

}
export default new Store();
